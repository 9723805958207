import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`Bent Leg Bridge 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`10-Back Squats (185/125)`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`8-Back Squats`}</p>
    <p>{`25-Double Unders`}</p>
    <p>{`6-Back Squats`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`4-Back Squats`}</p>
    <p>{`35-Double Unders`}</p>
    <p>{`2-Back Squats`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      